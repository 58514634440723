import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import close from '../../assets/close.png';

type Props = {
  children: React.ReactNode;
  action: () => void;
  body?: string;
  title?: string;
  submitLabel?: string;
};

const PopConfirm = ({
  children,
  action,
  body,
  title = 'Are you Sure ?',
  submitLabel = 'Submit',
}: Props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <span className='cur-pointer' onClick={() => setShow(true)}>{children}</span>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        centered
        dialogClassName="popConfirmationModal"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <img className='cur-pointer' onClick={e => setShow(false)} src={close} />
        </Modal.Header>
        {body ? <Modal.Body>{body}</Modal.Body> : <></>}
        <Modal.Footer>
          <Button
            className="cur-pointer btn-small bgColorGray"
            variant="secondary"
            onClick={() => {
              setShow(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="cur-pointer btn-small"
            variant="primary"
            onClick={() => {
              setShow(false);
              action();
            }}
          >
            {submitLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PopConfirm;
